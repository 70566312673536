import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactUs = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  //  Script読み込みのため、1回リロードさせる
  if (typeof window !== `undefined`) {
    if (window.name != "any") {
      window.location.reload(true);
      window.name = "any";
    } else {
      window.name = "";
    }
  }


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-mail bg-no-repeat bg-center bg-cover z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl pt-10 pb-20">Contact Us</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[800px] mx-auto py-10 sm:py-10">

            <section id="gaiyou" className="mb-20">
              <h2 className="text-2xl border-b border-key-sand mb-10 py-3 ">
                お問い合わせ
              </h2>
              <form className="" method="post" id="mailForm">
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="company" id="company" 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="company" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    会社名　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="department" id="department" 
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="department" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    部署名・役職名　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="text" name="name" id="name" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="name" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    お名前　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="email" name="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="email" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    メールアドレス　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <input type="tel" name="phone" id="phone" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required />
                  <label htmlFor="phone" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    電話番号　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="relative z-0 mb-10 w-full group">
                  <textarea name="message" id="message" rows="4" className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b border-key-sand appearance-none focus:outline-none focus:ring-0 focus:border-key-pink peer" 
                    placeholder=" " required ></textarea>
                  <label htmlFor="message" className="absolute duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-key-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    お問い合わせ内容　<small className=" text-key-pink">※必須</small>
                  </label>
                </div>
                <div className="mb-10">
                  <strong>【個人情報の管理について】</strong>
                  <p className="p-2 text-sm">
                  お問い合せの際にお送りいただく個人情報は、お問い合せへの返答に必要な連絡等の目的の範囲内で利用させていただきます。
                  その他個人情報の取扱いに関する詳細については、<br />
                  当社のプライバシーポリシー（<a href="https://www.startup-plus.com/privacy-policy/" target="_blank" rel="noopener noreferrer">http://www.startup-plus.com/privacy-policy/</a>）をご確認ください。<br />
                  個人情報の管理について同意いただける場合は下記「同意する」にチェックを付けて下さい。
                  </p>
                  <label htmlFor="agree" className="p-2">
                    <input type="checkbox" name="agree" required />
                    <span className="ml-2">同意する</span><small className=" text-key-pink">※必須</small>
                  </label>

                </div>

                  <button type="submit" id="button" className="text-white bg-key-sand hover:bg-key-pink focus:ring-4 font-medium rounded-lg text-sm w-full sm:w-1/2 px-5 py-2.5 text-center">送 信</button>
              </form>

            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default ContactUs

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`